<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('FAQ.tag')" prop="tag">
          <el-input v-model="ruleForm.tag"></el-input>
        </el-form-item>
        <el-form-item :label="$t('FAQ.order')" prop="order">
          <el-input
            @input="
              (res) => {
                this.ruleForm.order = +res;
              }
            "
            @mousewheel.native.prevent  
            @DOMMouseScroll.native.prevent 
            type="number"
            placeholder="정렬순이 높은 건이 우선 노출됩니다."
            v-model="ruleForm.order"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('FAQ.question')" prop="question">
          <el-input v-model="ruleForm.question"></el-input>
        </el-form-item>
        <el-form-item :label="$t('FAQ.answer')" prop="answer">
          <div v-if="load">
            <SummerNote
              :id="'summernote' + new Date().getTime()"
              v-model="ruleForm.answer"
            />
          </div>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import SummerNote from "@/components/summernote";
import { getFAQDetail, addFAQ, editFAQ } from "@/api/index";
export default {
  name: "editFAQ",
  data() {
    return {
      ruleForm: {
        tag: "",
        question: "",
        answer: "",
        order: 0,
      },
      rules: {
        tag: [{ required: true, message: this.$t("FAQ").tag, trigger: "blur" }],
        question: [
          { required: true, message: this.$t("FAQ").question, trigger: "blur" },
        ],
        order: [
          {
            required: true,
            message: "정렬순이 높은 건이 우선 노출됩니다.",
            trigger: "blur",
          },
        ],
        answer: [
          { required: true, message: this.$t("FAQ").answer, trigger: "blur" },
        ],
      },
      load: false,
    };
  },
  components: { SummerNote },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate();
      if (this.id === undefined) {
        await addFAQ(this.ruleForm);
      } else {
        delete this.ruleForm.active;
        await editFAQ(this.ruleForm);
      }
      this.$router.go(-1);
    },
    async get_info() {
      const information = await getFAQDetail(this.id);
      this.ruleForm = { ...information };
      this.load = true;
    },
  },
  async created() {
    if (this.id === undefined) {
      this.load = true;
      return;
    }
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
